<template>
<div>

  <v-card>
    <v-card-title>
      {{titulo}}
      <v-spacer></v-spacer>
      <v-text-field
        v-model="search"
        append-icon="fas fa-search"
        label="Buscar"
        single-line
        hide-details
      ></v-text-field>
    </v-card-title>
  
    <v-data-table
      :headers="headers"
      :items="currentItems"
      :search="search"
      sort-by="date_created"
      sort-desc
      class="elevation-1"
      :loading="loading"
      loading-text="Consultando datos... Espere por favor"
    >
      <template v-slot:[`item.validity_date1`]="{ item }">
        <v-chip
          :color="getColor(item.cancelled)"
          dark
        >
          {{ validityFormat(item.validity_date1, item.validity_date2) }}
        </v-chip>
      </template>
      <template v-slot:[`item.renewed`]="{ item }">
        <v-switch
          inset
          :input-value="item.renewed"
          @change="newValue => fnRenew(newValue, item.id)"
        ></v-switch>
      </template>
      <template v-slot:[`item.total_value`]="{ item }">
        <div v-if="item.type_currency == 1">
          <span style="color: red;">L</span> {{ item.total_value }}
        </div> 
        <div v-else>
          <span style="color: green;">$</span> {{ item.total_value }}
        </div>
      </template>

      <template v-slot:top>
        <v-toolbar flat color="white">

          <v-row>
            <v-col cols="12" sm="12" md="6">
              <v-form ref="form2" v-model="valid">
                <v-autocomplete
                  v-model="editedItem.id_insurance"
                  :items="insurers"
                  item-text="name"
                  item-value="id"
                  label="Escoge una Aseguradora"
                  clearable
                  rounded
                  solo
                  @change="getByInsurer"
                ></v-autocomplete>
              </v-form>
            </v-col>
          </v-row>

          <v-spacer></v-spacer>

          <v-dialog v-model="dialog" persistent max-width="1100px">
            <template v-slot:activator="{ on }">
                <v-btn ref="boton2" color="primary" dark v-on="on">Nuevo Registro</v-btn>
            </template>
            <v-card>
              <v-card-title>
                <span class="headline">{{ formTitle }}</span>
                <v-spacer></v-spacer>
               <v-radio-group
                 row
               >
                 <v-switch
                    v-model="editedItem.endorsed"
                    inset
                    label="Endosado"
                    class="mr-4"
                  ></v-switch>

                  <!-- <v-switch
                    v-model="editedItem.active"
                    readonly
                    inset
                    color="green"
                    label="Activa"
                  ></v-switch> -->
               </v-radio-group>
              </v-card-title>

              <v-card-text>
                <v-container>
                  <v-form ref="form" v-model="valid" lazy-validation>
                    <v-row dense>
                      <v-col cols="12" sm="12" md="6">
                      <v-autocomplete
                        v-model="editedItem.id_customer"
                        :items="customers"
                        item-text="name"
                        item-value="id"
                        label="Escoge un Cliente"
                        :rules="editedItem.id_customerRules"
                        clearable
                        rounded
                        solo
                      ></v-autocomplete>
                    </v-col>

                    <v-col cols="12" sm="12" md="6">
                        <v-autocomplete
                          v-model="editedItem.id_insurance"
                          :rules="editedItem.id_insuranceRules"
                          :items="insurers"
                          item-text="name"
                          item-value="id"
                          label="Escoge una Aseguradora"
                          clearable
                          rounded
                          solo
                        ></v-autocomplete>
                      </v-col>

                      <v-col cols="12" sm="12" md="4">
                        <v-select
                            v-model="editedItem.ramo"
                            :items="ramos"
                            label="Ramo"
                            item-text="name"
                            item-value="id"
                            :rules="editedItem.ramoRules"
                            @change="getBranches"
                            chips
                            solo
                            rounded
                            clearable
                            return-object
                          >
                          </v-select>
                      </v-col>

                      <v-col cols="12" sm="12" md="4"
                      v-if="editedItem.ramo != null && editedItem.ramo.id<3"
                      >
                        <v-select
                            v-model="editedItem.type"
                            :rules="editedItem.typeRules"
                            :items="branches"
                            label="Tipo"
                            item-text="name"
                            item-value="id"
                            chips
                            solo
                            rounded
                            clearable
                            return-object
                          >
                          </v-select>
                      </v-col>

                      <v-col cols="12" sm="12" md="4">
                        <v-text-field
                          v-model="editedItem.policy_number"
                          label="Número de Póliza"
                          :rules="editedItem.policy_numberRules"
                          autocomplete="new-name"
                          required
                        ></v-text-field>
                      </v-col>

                      <v-col cols="12" sm="12" md="4">
                        <v-text-field
                          v-model="editedItem.client_code"
                          label="Código de Cliente"
                          autocomplete="new-surname"
                          required
                        ></v-text-field>
                      </v-col>

                      <v-col cols="12" sm="12" md="4">
                        <v-menu
                          ref="menu1"
                          v-model="menu1"
                          :close-on-content-click="false"
                          transition="scale-transition"
                          offset-y
                          max-width="290px"
                          min-width="auto"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              v-model="editedItem.date"
                              :rules="editedItem.dateRules"
                              label="Vigencia"
                              prepend-icon="mdi-calendar"
                              v-bind="attrs"
                              v-on="on"
                              readonly
                            ></v-text-field>
                          </template>
                          <v-date-picker
                            v-model="editedItem.date"
                            no-title
                            @input="menu1 = false"
                            @change="saveDate"
                          ></v-date-picker>
                        </v-menu>
                      </v-col>
                      
                      <v-col cols="12" sm="12" md="4">
                        <v-radio-group
                          v-model="editedItem.type_currency"
                          row
                        >
                          <v-radio
                            label="Lempiras"
                            value="1"
                          ></v-radio>
                          <v-radio
                            color="success"
                            label="Dólares"
                            value="2"
                          ></v-radio>
                        </v-radio-group>
                      </v-col>

                      <v-col cols="12" sm="12" md="4">
                        <v-text-field
                          v-model="editedItem.amount_insured"
                          :rules="editedItem.amount_insuredRules"
                          label="Suma Asegurada"
                          v-mask="currencyMask"
                        ></v-text-field>
                      </v-col>

                      <v-col cols="12" sm="12" md="4">
                        <v-text-field
                          v-model="editedItem.initial_value"
                          :rules="editedItem.initial_valueRules"
                          label="Prima Neta"
                          v-mask="currencyMask"
                        ></v-text-field>
                      </v-col>

                      <v-col cols="12" sm="12" md="4"
                        v-if="editedItem.type != null && editedItem.type.car==1"
                      >
                        <v-text-field
                          v-model="editedItem.occupants"
                          :rules="editedItem.occupantsRules"
                          label="Gastos de ocupantes"
                          v-mask="currencyMask"
                        ></v-text-field>
                      </v-col>
                      
                      <v-col cols="12" sm="12" md="4">
                        <v-text-field
                          v-model="editedItem.emision_cost"
                          :rules="editedItem.emision_costRules"
                          label="Gastos de Emisión"
                          v-mask="currencyMask"
                        ></v-text-field>
                      </v-col>

                      <v-col cols="12" sm="12" md="4"
                      v-if="editedItem.ramo != null && editedItem.ramo.id==2">
                        <v-text-field
                          v-model="editedItem.firefighter_contribution"
                          label="Contribución de Bomberos"
                          v-mask="currencyMask"
                        ></v-text-field>
                      </v-col>
                      
                      <!-- Fecha del primer pago -->
                      <v-col cols="12" sm="12" md="4">
                        <v-menu
                          ref="menu2"
                          v-model="menu2"
                          :close-on-content-click="false"
                          transition="scale-transition"
                          offset-y
                          max-width="290px"
                          min-width="auto"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              v-model="editedItem.payment_date"
                              :rules="editedItem.payment_dateRules"
                              label="Fecha (Primer Pago)"
                              prepend-icon="mdi-calendar"
                              v-bind="attrs"
                              v-on="on"
                              readonly
                            ></v-text-field>
                          </template>
                          <v-date-picker
                            v-model="editedItem.payment_date"
                            no-title
                            @input="menu2 = false"
                          ></v-date-picker>
                        </v-menu>
                      </v-col>

                      <v-col cols="12" sm="12" md="4">
                        <v-text-field
                          v-model="editedItem.numbers_pays"
                          :rules="editedItem.numbers_paysRules"
                          label="Número de Pagos"
                        ></v-text-field>
                      </v-col>

                      <v-col cols="12" sm="12" md="4"
                        v-if="editedItem.endorsed"
                      >
                        <v-autocomplete
                          v-model="editedItem.id_bank"
                          :rules="editedItem.id_bankRules"
                          :items="banks"
                          item-text="name"
                          item-value="id"
                          label="Escoge un Banco"
                          clearable
                          rounded
                          solo
                        ></v-autocomplete>
                      </v-col>
                      
                      <v-col cols="12" sm="12" md="12">
                        <v-textarea
                          solo
                          auto-grow
                          outlined
                          rows="3"
                          v-model="editedItem.comment"
                          label="Observaciones"
                        ></v-textarea>
                      </v-col>

                    </v-row>
                  </v-form>
                </v-container>
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="close"> Cancelar</v-btn>
                <v-btn 
                  color="blue darken-1" text 
                  :loading="loading1"
                  :disabled="loading1" 
                  @click="save">
                  Guardar
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>

          <v-dialog v-model="dialogCancel" max-width="550">
            <v-card>
              <v-card-title>
                <span>¿Desea cancelar póliza #{{ editedItem.policy_number }}?</span>
                <v-spacer></v-spacer>
              </v-card-title>
              <v-card-text>
                <template>
                <!-- <div> <b>N° de Póliza:</b> {{ editedItem.policy_number }} </div> -->
                <!-- <div> <b>Vigencia Desde:</b> {{ editedItem.validity_date1 }} </div>
                <div> <b>Vigencia Hasta:</b> {{ editedItem.validity_date2 }} </div>
                <div> <b>Código de Cliente:</b> {{ editedItem.client_code }} </div> -->
                <div> <b>Nombre:</b> {{ editedItem.name }} {{ editedItem.surname }} </div>
                <!-- <div> <b>Apellidos:</b> {{ editedItem.surname }} </div> -->
                <br>
                  <v-row>
                    <v-col cols="12" sm="12" md="12">
                      <v-file-input
                        v-model="editedItem.letters_file"
                        color="deep-purple accent-4"
                        counter
                        placeholder="Selecciona el archivo de las Cartas"
                        prepend-icon="mdi-paperclip"
                        accept="application/pdf"
                        outlined
                        :show-size="1000"
                      >
                        <template v-slot:selection="{ index, text }">
                          <v-chip
                            v-if="index < 2"
                            color="deep-purple accent-4"
                            dark
                            label
                            small
                          >
                            {{ text }}
                          </v-chip>
                        </template>
                      </v-file-input>
                    </v-col>

                    <v-col cols="12" sm="12" md="12">
                      <v-file-input
                        v-model="editedItem.release_file"
                        color="deep-purple accent-4"
                        counter
                        placeholder="Selecciona el archivo de la Liberación"
                        prepend-icon="mdi-paperclip"
                        accept="application/pdf"
                        outlined
                        :show-size="1000"
                      >
                        <template v-slot:selection="{ index, text }">
                          <v-chip
                            v-if="index < 2"
                            color="deep-purple accent-4"
                            dark
                            label
                            small
                          >
                            {{ text }}
                          </v-chip>
                        </template>
                      </v-file-input>
                    </v-col>

                    <v-col cols="12" sm="12" md="12"
                    >
                      <v-file-input
                        v-model="editedItem.endorsement_file"
                        color="deep-purple accent-4"
                        counter
                        placeholder="Selecciona el archivo del Endoso"
                        prepend-icon="mdi-paperclip"
                        accept="application/pdf"
                        outlined
                        :show-size="1000"
                      >
                        <template v-slot:selection="{ index, text }">
                          <v-chip
                            v-if="index < 2"
                            color="deep-purple accent-4"
                            dark
                            label
                            small
                          >
                            {{ text }}
                          </v-chip>
                        </template>
                      </v-file-input>
                    </v-col>
                </v-row>
                </template>
              </v-card-text>
              <v-card-actions>

                <v-row no-gutters style="flex-wrap: nowrap;">
                <v-btn
                  class="flex-grow-1 flex-shrink-0"
                  large
                  rounded
                  outlined
                  color="warning"
                  @click="close"
                  >Cancelar</v-btn
                >
                <v-btn
                  class="flex-grow-1 flex-shrink-0"
                  :loading="loading2"
                  :disabled="loading2"
                  large
                  rounded
                  color="error"
                  @click="policyCancelled"
                  >Aceptar
                    <v-icon right dark > mdi-cloud-upload </v-icon>
                  </v-btn
                >
              </v-row>

              </v-card-actions>
            </v-card>
          </v-dialog>

          <v-dialog v-model="dialogDelete" max-width="550">
            <v-card>
              <v-card-title>
                <span>¿Seguro que desea eliminar póliza #{{ editedItem.policy_number }}?</span>
                <v-spacer></v-spacer>
              </v-card-title>
              <v-card-text>
                <template>
                <div> <b>N° de Póliza:</b> {{ editedItem.policy_number }} </div>
                <div> <b>Vigencia Desde:</b> {{ editedItem.validity_date1 }} </div>
                <div> <b>Vigencia Hasta:</b> {{ editedItem.validity_date2 }} </div>
                <div> <b>Código de Cliente:</b> {{ editedItem.client_code }} </div>
                <div> <b>Nombre:</b> {{ editedItem.name }} {{ editedItem.surname }} </div>
                <div> <b>Apellidos:</b> {{ editedItem.surname }} </div>
                <br>
                </template>
              </v-card-text>
              <v-card-actions>

                <v-row no-gutters style="flex-wrap: nowrap;">
                <v-btn
                  class="flex-grow-1 flex-shrink-0"
                  large
                  rounded
                  outlined
                  color="warning"
                  @click="close"
                  >Cancelar</v-btn
                >
                <v-btn
                  class="flex-grow-1 flex-shrink-0"
                  :loading="loading2"
                  :disabled="loading2"
                  large
                  rounded
                  color="error"
                  @click="deletePolicy"
                  >Eliminar
                    <v-icon right dark > mdi-delete </v-icon>
                  </v-btn
                >
              </v-row>

              </v-card-actions>
            </v-card>
          </v-dialog>

          <v-dialog v-model="dialogUpload" max-width="650">
            <v-card>
              <v-card-title>
                <span>Subir documentos de Póliza #{{editedItem.policy_number}}</span>
                <v-spacer></v-spacer>
              </v-card-title>

              <v-card-text>
                <template>
                  <v-row>
                      <v-col cols="12" sm="12" md="12">
                        <v-file-input
                          v-model="editedItem.request_file"
                          color="deep-purple accent-4"
                          counter
                          :label="path_label_request"
                          placeholder="Selecciona el archivo de la Solicitud"
                          prepend-icon="mdi-paperclip"
                          accept="application/pdf"
                          outlined
                          :show-size="1000"
                        >
                          <template v-slot:selection="{ index, text }">
                            <v-chip
                              v-if="index < 2"
                              color="deep-purple accent-4"
                              dark
                              label
                              small
                            >
                              {{ text }}
                            </v-chip>

                            <span
                              v-else-if="index === 2"
                              class="text-overline grey--text text--darken-3 mx-2"
                            >
                              +{{ files.length - 2 }} File(s)
                            </span>
                          </template>
                        </v-file-input>
                      </v-col>

                      <v-col cols="12" sm="12" md="12">
                        <v-file-input
                          v-model="editedItem.policy_file"
                          color="deep-purple accent-4"
                          counter
                          :label="path_label_policy"
                          placeholder="Selecciona el archivo de la Póliza"
                          prepend-icon="mdi-paperclip"
                          accept="application/pdf"
                          outlined
                          :show-size="1000"
                        >
                          <template v-slot:selection="{ index, text }">
                            <v-chip
                              v-if="index < 2"
                              color="deep-purple accent-4"
                              dark
                              label
                              small
                            >
                              {{ text }}
                            </v-chip>

                            <span
                              v-else-if="index === 2"
                              class="text-overline grey--text text--darken-3 mx-2"
                            >
                              +{{ files.length - 2 }} File(s)
                            </span>
                          </template>
                        </v-file-input>
                      </v-col>
                  </v-row>
                  
                </template>
              </v-card-text>

              <v-card-actions>

                <v-row no-gutters style="flex-wrap: nowrap;">
                  <v-btn
                    class="flex-grow-1 flex-shrink-0"
                    large
                    rounded
                    outlined
                    color="gray"
                    @click="close"
                  >Cancelar</v-btn>
                  <v-btn
                    :loading="loading3"
                    :disabled="loading3"
                    class="flex-grow-1 flex-shrink-0 white--text"
                    large
                    rounded
                    color="deep-purple"
                    @click="upload"
                    >Subir
                      <v-icon right dark > mdi-cloud-upload </v-icon>
                    </v-btn>
                </v-row>

              </v-card-actions>
            </v-card>
          </v-dialog>

          <!-- COMPONENTS -->
          <v-dialog v-model="dialogIncrement" max-width="850">
            <v-card> 
              <v-spacer></v-spacer>
              <div>
                <v-btn
                  style="right:10px; top:10px;"
                  icon
                  color="error"
                  fab
                  absolute
                  @click="dialogIncrement = false"
                >
                  <v-icon dark>fa-times</v-icon>
                </v-btn>
              </div>

              <Increments :key="editedItem.id" :policy="editedItem.id" v-if="dialogIncrement"></Increments>
            </v-card>
          </v-dialog>

          <v-dialog v-model="dialogRenovation" max-width="850">
            <v-card> 
              <v-spacer></v-spacer>
              <div>
                <v-btn
                  style="right:10px; top:10px;"
                  icon
                  color="error"
                  fab
                  absolute
                  @click="dialogRenovation = false"
                >
                  <v-icon dark>fa-times</v-icon>
                </v-btn>
              </div>

              <Renovations :key="editedItem.id" :policy="editedItem.id" v-if="dialogRenovation"></Renovations>
            </v-card>
          </v-dialog>

          <v-dialog v-model="dialogProperty" max-width="850">
            <v-card> 
              <v-spacer></v-spacer>
              <div>
                <v-btn
                  style="right:10px; top:10px;"
                  icon
                  color="error"
                  fab
                  absolute
                  @click="dialogProperty = false"
                >
                  <v-icon dark>fa-times</v-icon>
                </v-btn>
              </div>

              <Properties :key="editedItem.id" :policy="editedItem.id" v-if="dialogProperty"></Properties>
            </v-card>
          </v-dialog>

          <v-dialog v-model="dialogCar" max-width="850">
            <v-card> 
              <v-spacer></v-spacer>
              <div>
                <v-btn
                  style="right:10px; top:10px;"
                  icon
                  color="error"
                  fab
                  absolute
                  @click="dialogCar = false"
                >
                  <v-icon dark>fa-times</v-icon>
                </v-btn>
              </div>

              <Cars :key="editedItem.id" :id_policy="editedItem.id" v-if="dialogCar"></Cars>
            </v-card>
          </v-dialog>

          <v-dialog v-model="dialogInsured" max-width="850">
            <v-card> 
              <v-spacer></v-spacer>
              <div>
                <v-btn
                  style="right:10px; top:10px;"
                  icon
                  color="error"
                  fab
                  absolute
                  @click="dialogInsured = false"
                >
                  <v-icon dark>fa-times</v-icon>
                </v-btn>
              </div>

              <Policyholders v-if="dialogInsured" :key="editedItem.id" :id_customer="editedItem.id_customer" :id_policy="editedItem.id"></Policyholders>
            </v-card>
          </v-dialog>

        </v-toolbar>
      </template>

      <template v-slot:[`item.action`]="{ item }">
        <div v-if="item.cancelled==0">
        <v-tooltip top color="purple" v-if="item.policyholder>0">
          <template v-slot:activator="{ on, attrs }">
            <v-icon color="pink" v-bind="attrs" v-on="on" small class="mr-3" @click="insuredPolicy(item)">fas fa-person-circle-plus</v-icon>
          </template>
          <span>Asegurados</span>
        </v-tooltip>

        <v-tooltip top color="purple" v-if="item.car>0">
          <template v-slot:activator="{ on, attrs }">
            <v-icon v-bind="attrs" color="blue darken-3" v-on="on" small class="mr-3" @click="carPolicy(item)">fas fa-car-burst</v-icon>
          </template>
          <span>Autos</span>
        </v-tooltip>

        <v-tooltip top color="purple" v-if="item.property>0">
          <template v-slot:activator="{ on, attrs }">
            <v-icon v-bind="attrs" color="green darken-3" v-on="on" small class="mr-3" @click="propertyPolicy(item)">fas fa-city</v-icon>
          </template>
          <span>Bienes</span>
        </v-tooltip>

        <v-tooltip top color="purple">
          <template v-slot:activator="{ on, attrs }">
            <v-icon v-bind="attrs" v-on="on" small class="mr-3" @click="renovationPolicy(item)">fas fa-window-restore</v-icon>
          </template>
          <span>Renovar Póliza</span>
        </v-tooltip>

        <v-tooltip top color="purple">
          <template v-slot:activator="{ on, attrs }">
            <v-icon v-bind="attrs" v-on="on" color="primary" small class="mr-3" @click="incrementPolicy(item)">fas fa-arrow-up-right-dots</v-icon>
          </template>
          <span>Incrementos</span>
        </v-tooltip>

        <v-tooltip top color="purple">
          <template v-slot:activator="{ on, attrs }">
            <v-icon v-bind="attrs" v-on="on" small class="mr-3" @click="uploadFile(item)">fas fa-file-upload</v-icon>
          </template>
          <span>Subir documentos</span>
        </v-tooltip>
        
        <v-tooltip top color="purple">
          <template v-slot:activator="{ on, attrs }">
            <v-icon v-bind="attrs" v-on="on" small class="mr-3" color="red darken-3" v-if="master==1" @click="cancelItem(item)">fas fa-folder-minus</v-icon>
          </template>
          <span>Cancelar Póliza</span>
        </v-tooltip>
        <v-icon small color="green" class="mr-3" @click="editItem(item)">fas fa-edit</v-icon>
        <v-icon small color="red" v-if="master==1" @click="deleteItem(item)">fas fa-trash-alt</v-icon>
        </div>
      </template>
    </v-data-table>
  </v-card>
</div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import axios from "axios";
import * as moment from 'moment';
import jwt_decode from "jwt-decode";
import Increments from '@/components/Increments.vue';
import Renovations from '@/components/Renovations.vue';
import Properties from '@/components/Properties.vue';
import Cars from '@/components/Cars.vue';
import Policyholders from '@/components/Policyholders.vue';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';

export default {
  components: { Increments, Renovations, Properties, Cars, Policyholders },
  name: "policy",
  data: () => ({
    dialog: false,
    master: jwt_decode(localStorage.token).master || '',
    customers: [],
    policies: [],
    currentItems: [],
    insurers: [],
    branches: [],
    banks: [],
    renewed: false,
    dialogCancel: false,
    dialogDelete: false,
    dialogUpload: false,
    dialogRenew: false,
    dialogIncrement: false,
    dialogRenovation: false,
    dialogProperty: false,
    dialogCar: false,
    dialogInsured: false,
    menu1: false,
    menu2: false,
    menu3: false,
    loader: null,
    loading3: false,
    loading2: false,
    loading1: false,
    search: "",
    loading: "",
    titulo: "Pólizas",
    activePicker: null,
    menu: false,
    headers: [
      { text: "Vigencia", align: "left", value: "validity_date1"},
      { text: "#Cliente", align: "left", value: "client_code" },
      { text: "Nombres", align: "left", value: "name" },
      { text: "Apellidos", align: "left", value: "surname" },
      { text: "#Póliza", align: "left", value: "policy_number" },
      { text: "Aseguradora", align: "left", value: "insurance" },
      { text: "Banco", align: "left", value: "bank" },
      { text: "Ramo", align: "left", value: "branch" },
      { text: "Prima Neta", align: "left", value: "initial_value" },
      { text: "Prima Total", align: "left", width:"120", value: "total_value" },
      { text: "Renovada", align: "left", value: "renewed" },
      // { text: "Documentos", value: "path" },
      { text: "Acciones", value: "action", width:"220", sortable: false }
    ],
    ramos: [
      { id: 1, name: "VIDA" },
      { id: 2, name: "DAÑOS" },
      { id: 3, name: "FIANZAS" },
    ],
    coins: [
      { id: 1, name: "Lempiras" },
      { id: 2, name: "Dólares" },
    ],
    editedIndex: -1,
    currencyMask: createNumberMask({
                      prefix: '',
                      allowDecimal: true,
                      includeThousandsSeparator: true,
                      allowNegative: false,
                    }),
    valid: true,
    editedItem: {
      id: "",
      id_customer: "",
      ramo: "",
      id_bank: "",
      type: "",
      endorsed: false,
      renewed: false,
      date: "",
      validity_date1: "",
      validity_date2: "",
      payment_date: "",
      id_insurance: "",
      policy_number: "",
      client_code: "",
      type_currency: "2",
      initial_value: "",
      emision_cost: "",
      firefighter_contribution: "",
      amount_insured: "",
      occupants: "",
      total_value: "",
      numbers_pays: "",
      calcule: "",
      comment: "",

      letters_file: [],
      release_file: [],
      endorsement_file: [],

      request_file: [],
      policy_file: [],

      ramoRules: [v => !!v || "Escoge un ramo"],
      id_bankRules: [v => !!v || "Escoge un banco"],
      typeRules: [v => !!v || "Escoge un tipo de ramo"],
      dateRules: [v => !!v || "Escoge la vigencia"],
      payment_dateRules: [v => !!v || "Escoge una fecha de pago"],
      id_insuranceRules: [v => !!v || "Escoge una aseguradora"],
      id_customerRules: [v => !!v || "Escoge un cliente"],
      policy_numberRules: [v => !!v || "Escribe el número de póliza"],
      type_currencyRules: [v => !!v || "Escoge la moneda"],
      amount_insuredRules: [v => !!v || "Escribe la suma asegurada"],
      initial_valueRules: [v => !!v || "Escribe la prima neta"],
      emision_costRules: [v => !!v || "Escribe los gastos de emisión"],

      numbers_paysRules: [
                            v => !!v || "Escribe el número de pagos",
                            v => (v <= 12 && v > 0) || 'El número debe estar entre 1 - 12'
                         ],
      active: true
    },

    defaultItem: {
      id_customer: "",
      ramo: "",
      id_bank: "",
      type: "",
      endorsed: false,
      renewed: false,
      date: "",
      validity_date1: "",
      validity_date2: "",
      payment_date: "",
      id_insurance: "",
      policy_number: "",
      client_code: "",
      type_currency: "2",
      initial_value: "",
      emision_cost: "",
      firefighter_contribution: "",
      amount_insured: "",
      occupants: "",
      total_value: "",
      numbers_pays: "",
      calcule: "",
      comment: "",

      letters_file: [],
      release_file: [],
      endorsement_file: [],

      request_file: [],
      policy_file: [],

      ramoRules: [v => !!v || "Escoge un ramo"],
      id_bankRules: [v => !!v || "Escoge un banco"],
      typeRules: [v => !!v || "Escoge un tipo de ramo"],
      dateRules: [v => !!v || "Escoge la vigencia"],
      payment_dateRules: [v => !!v || "Escoge una fecha de pago"],
      id_insuranceRules: [v => !!v || "Escoge una aseguradora"],
      id_customerRules: [v => !!v || "Escoge un cliente"],
      policy_numberRules: [v => !!v || "Escribe el número de póliza"],
      type_currencyRules: [v => !!v || "Escoge la moneda"],
      amount_insuredRules: [v => !!v || "Escribe la suma asegurada"],
      initial_valueRules: [v => !!v || "Escribe la prima neta"],
      emision_costRules: [v => !!v || "Escribe los gastos de emisión"],

      numbers_paysRules: [
                            v => !!v || "Escribe el número de pagos",
                            v => (v <= 12 && v > 0) || 'El número debe estar entre 1 - 12'
                         ],
      active: true
    }
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "Nuevo Registro" : "Editar Registro";
    },
    path_label_request(){
      return this.editedItem.filename_request;
    },
    path_label_policy(){
      return this.editedItem.filename_policy;
    },
    ...mapState(["db","headers_db"]),
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    loader () {
        const l = this.loader
        this[l] = !this[l]

        setTimeout(() => (this[l] = false), 1000)

        this.loader = null
    },
  },

  methods: {
    ...mapActions(["cerrarSesion", "validateSession"]),

    // Metodos base de datos
    async getPolicies(){
      await axios.get(this.db + 'policies',
        {
          headers: this.headers_db
        }).then(response => {
          this.policies = response.data.data;
          this.policies.forEach(item => {
            item.type = {
              id: item.type,
              name: item.branch,
              car: item.car
            }
            item.renewed = (item.renewed == 1) ? true : false;
          });
          this.currentItems = this.policies;
          this.loading = false;
          console.log(this.policies);
        })
        .catch((error) => {
          console.log(error);
          this.loading = false;
          this.validateSession(error).then((resp) => { if(resp) this.getPolicies(); })
        });
    },
    
    async getCustomers(){
      await axios.get(this.db + 'list/customers',
        {
          headers: this.headers_db
        }).then(response => {
          this.customers = response.data.data;
          this.loading = false;
          console.log(response.data.data);
        })
        .catch((error) => {
          this.validateSession(error).then((resp) => { if(resp) this.getCustomers(); })
        });
    },

    async getInsurers(){
      await axios.get(this.db + 'insurers',
        {
          headers: this.headers_db
        }).then(response => {
          this.insurers = response.data.data;
          this.loading = false;
          console.log(response.data.data);
        })
        .catch((error) => {
          this.validateSession(error).then((resp) => { if(resp) this.getInsurers(); })
        });
    },

    async getBranches(){
      let branch = this.editedItem.ramo;
      this.branches = [];
      if (branch == null) {
        return;
      }
      if (branch.id > 2) {
        return;
      }
      await axios.get(this.db + 'branches/' + branch.id,
        {
          headers: this.headers_db
        }).then(response => {
          this.branches = response.data.data;
          console.log(response.data.data);
        })
        .catch((error) => {
          this.validateSession(error).then((resp) => { if(resp) this.getBranches(); })
        });
    },

    async getBranch(id){
      await axios.get(this.db + 'branches/' + id,
        {
          headers: this.headers_db
        }).then(response => {
          this.branches = response.data.data;
        })
        .catch((error) => {
          this.validateSession(error).then((resp) => { if(resp) this.getBranch(id); })
        });
    },

    async getBanks(){
      await axios.get(this.db + 'banks',
        {
          headers: this.headers_db
        }).then(response => {
          this.banks = response.data.data;
          this.loading = false;
          console.log(response.data.data);
        })
        .catch((error) => {
          this.validateSession(error).then((resp) => { if(resp) this.getBanks(); })
        });
    },

    addCustomer(payload){
      console.log("payload",payload);
      payload.id_user = localStorage.id_user;
      this.loader = 'loading1'
      axios.post(this.db + 'policy/add', payload,
      {
        headers: this.headers_db
      })
      .then((response) => {
        console.log(response);
        this.$store.dispatch("setSnackbar", {
          text: response.data.message,
        });
        this.getPolicies();
        this.close();
      })
      .catch((error) => {
        console.log({error});
        this.validateSession(error).then((resp) => { if(resp) this.addCustomer(payload); })
      });

    },

    updateCustomer(payload){
      console.log("payload",payload);
      payload.id_user = localStorage.id_user;
      this.loader = 'loading1'
      axios.post(this.db + 'policy/update', payload,
      {
        headers: this.headers_db
      })
      .then((response) => {
        console.log(response.data.message);
        this.$store.dispatch("setSnackbar", {
          text: response.data.message,
        });
        this.getPolicies();
        this.close();
      })
      .catch((error) => {
        console.log({error});
        this.validateSession(error).then((resp) => { if(resp) this.updateCustomer(payload); })
      });

    },

    async upload() {
      this.loader = 'loading3'
      let reload = false;
      let headers = 
      {
          'Content-Type': 'multipart/form-data',
          'Authorization': 'BEARER ' + localStorage.token
      };
      
      if (this.editedItem.request_file != undefined && this.editedItem.request_file.size > 0) {
          let formData = new FormData();
          // files
          formData.append('path', 'path_request');
          formData.append('path_name', 'filename_request');
          formData.append('name', this.editedItem.request_file.name);
          formData.append('files', this.editedItem.request_file);
          formData.append('id', this.editedItem.id);
          
          // return;
          await axios.post(this.db + "policy/upload", formData,
          {
            headers:headers
          })
          .then(response => {
              this.$store.dispatch("setSnackbar", {
                text: 'Solicitud subida con éxito',
              });
              console.log({ response });
          })
          .catch(error => {
              if (error.response.status == 401) {
                reload = true;
              }
              this.$store.dispatch("setSnackbar", {
                text: 'No se ha podido subir la Solicitud',
                color: 'error'
              });
          });
      } else {
          console.log("there are no files.");
      }

      if (this.editedItem.policy_file != undefined && this.editedItem.policy_file.size > 0) {
          let formData = new FormData();
          console.log(this.editedItem.policy_file);
          // files
          formData.append('path', 'path_policy');
          formData.append('path_name', 'filename_policy');
          formData.append('name', this.editedItem.policy_file.name);
          formData.append('files', this.editedItem.policy_file);
          formData.append('id', this.editedItem.id);
          
          // return;
          await axios.post(this.db + "policy/upload", formData,
          {
            headers:headers
          })
          .then(response => {
              this.$store.dispatch("setSnackbar", {
                text: 'Póliza subida con éxito',
              });
              console.log({ response });
          })
          .catch(error => {
              console.log(error);
              if (error.response.status == 401) {
                reload = true;
              }
              this.$store.dispatch("setSnackbar", {
                text: 'No se ha podido subir la Póliza',
                color: 'error'
              });
          });
      } else {
          console.log("there are no files.");
      }

      if (reload) {
        this.getBanks();
      }
      this.dialogUpload = false;
    },

    // CANCELAR POLIZA
    async policyCancelled() {
      this.loader = 'loading2'
      if ((this.editedItem.letters_file == undefined || 
            this.editedItem.release_file == undefined || 
            this.editedItem.endorsement_file == undefined)) 
      {
        this.$store.dispatch("setSnackbar", {
          text: 'Complete todos los campos',
          color: 'warning'
        });
        return;
      }
      let reload = false;
      let headers = 
      {
          'Content-Type': 'multipart/form-data',
          'Authorization': 'BEARER ' + localStorage.token
      };

      if (this.editedItem.letters_file.size > 0) {
          let formData = new FormData();
          console.log(this.request_file);
          // files
          formData.append('path', 'path_letters');
          formData.append('path_name', 'filename_letters');
          formData.append('name', this.editedItem.letters_file.name);
          formData.append('files', this.editedItem.letters_file);
          formData.append('id', this.editedItem.id);
          
          // return;
          await axios.post(this.db + "policy/cancelled", formData,
          {
            headers:headers
          })
          .then(response => {
              this.$store.dispatch("setSnackbar", {
                text: 'Cartas subidas con éxito',
              });
              console.log({ response });
          })
          .catch(error => {
              if (error.response.status == 401) {
                reload = true;
              }
              this.$store.dispatch("setSnackbar", {
                text: 'No se han podido subir las Cartas',
                color: 'error'
              });
          });
      } else {
          console.log("there are no files.");
      }

      if (this.editedItem.release_file.size > 0) {
          let formData = new FormData();
          console.log(this.policy_file);
          // files
          formData.append('path', 'path_release');
          formData.append('path_name', 'filename_release');
          formData.append('name', this.editedItem.release_file.name);
          formData.append('files', this.editedItem.release_file);
          formData.append('id', this.editedItem.id);
          
          // return;
          await axios.post(this.db + "policy/cancelled", formData,
          {
            headers:headers
          })
          .then(response => {
              this.$store.dispatch("setSnackbar", {
                text: 'Liberación subida con éxito',
              });
              console.log({ response });
          })
          .catch(error => {
              console.log(error);
              if (error.response.status == 401) {
                reload = true;
              }
              this.$store.dispatch("setSnackbar", {
                text: 'No se ha podido subir la Liberación',
                color: 'error'
              });
          });
      } else {
          console.log("there are no files.");
      }

      if (this.editedItem.endorsement_file.size > 0) {
          let formData = new FormData();
          console.log(this.renew_file);
          // files
          formData.append('path', 'path_endorsement');
          formData.append('path_name', 'filename_endorsement');
          formData.append('name', this.editedItem.endorsement_file.name);
          formData.append('files', this.editedItem.endorsement_file);
          formData.append('id', this.editedItem.id);
          
          // return;
          await axios.post(this.db + "policy/cancelled", formData,
          {
            headers:headers
          })
          .then(response => {
              this.$store.dispatch("setSnackbar", {
                text: 'Endoso subido con éxito',
              });
              this.getPolicies();
              console.log({ response });
          })
          .catch(error => {
              console.log(error);
              if (error.response.status == 401) {
                reload = true;
              }

              this.$store.dispatch("setSnackbar", {
                text: 'No se ha podido subir el Endoso',
                color: 'error'
              });
          });
      } else {
          console.log("there are no files.");
      }
      if (reload) {
        this.getBanks();
      }
      this.dialogCancel = false;
    },

    deletePolicy(){
      let id = this.editedItem.id;
      axios.post(this.db + 'policy/delete', { id },
      {
        headers: this.headers_db
      })
      .then((response) => {
        console.log(response);
        this.policies = this.policies.filter(doc => {
          return doc.id != id
        })
        this.currentItems = this.policies;
        this.close();
      })
      .catch((error) => {
        this.validateSession(error).then((resp) => { if(resp) this.deletePolicy(); })
      });
    },

    fnRenew(value, id){
      console.log(value, id);
      let status = value ? 1 : 0;
      
      axios.post(this.db + 'policy/renewed', { id: id, renewed: status },
      {
        headers: this.headers_db
      })
      .then((response) => {
        console.log(response);

        this.$store.dispatch("setSnackbar", {
                text: 'Éxito al actualizar',
        });
      })
      .catch((error) => {
        console.log(error);
        this.$store.dispatch("setSnackbar", {
                text: error,
                color: 'error'
        });
        this.validateSession(error).then((resp) => { if(resp) this.fnRenew(id); })
      });

    },
    // FIN Metodos base de datos


    // Metodos Generales

    getByInsurer() {
      if (this.editedItem.id_insurance) {
        this.currentItems = this.policies.filter(doc => {
          return doc.id_insurance == this.editedItem.id_insurance;
        });
      }
      else {
        this.currentItems = this.policies;
      }
    },

    getColor (type) {
        if (type == 1) return 'red darken-3'
        else return ''
    },

    validityFormat(date1, date2) {
      date1 = moment(date1).format('DD/MM/YYYY');
      date2 = moment(date2).format('YYYY');
      return date1 + ' ~ ' + date2;
    },

    saveDate(date) {
      let date2 = moment(date).add(1, 'years').format('YYYY-MM-DD');
      
      this.editedItem.date = date + ' ~ ' + date2;
      this.editedItem.validity_date1 = date;
      this.editedItem.validity_date2 = date2;
      // this.$refs.menu1.save(date);
    },

    editItem(item) {
      // this.$refs.form.resetValidation();
      if (item.id_ramo > 0) {
        this.getBranch(item.id_ramo)
        item.ramo = this.ramos.filter(doc => {
          return doc.id == item.id_ramo
        })[0];
      }
      item.endorsed = item.endorsed == 1 ? true : false;
      item.active = item.active == 1 ? true : false;
      item.date = item.validity_date1 + ' ~ ' + item.validity_date2;
      // item.initial_value = this.numberWithCommas(item.initial_value);
      this.editedIndex = this.policies.indexOf(item);
      
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    numberWithCommas(x) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },

    cancelItem(item) {
      console.log(item.id);

      this.editedItem = Object.assign({}, item);
      this.dialogCancel = true;
    },

    deleteItem(item) {
      console.log(item.id);

      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    uploadFile(item){
      console.log(item);
      this.editedItem = Object.assign({}, item);
      this.dialogUpload = true;
    },

    renewPolicy(item){
      this.editedItem = Object.assign({}, item);
      this.dialogRenew = true;
    },

    incrementPolicy(item){
      this.editedItem = Object.assign({}, item);
      console.log(item.id);
      this.dialogIncrement = true;
    },

    renovationPolicy(item){
      this.editedItem = Object.assign({}, item);
      console.log(item.id);
      this.dialogRenovation = true;
    },

    propertyPolicy(item){
      this.editedItem = Object.assign({}, item);
      console.log(item.id);
      this.dialogProperty = true;
    },

    carPolicy(item){
      this.editedItem = Object.assign({}, item);
      console.log(item.id);
      this.dialogCar = true;
    },

    insuredPolicy(item){
      this.editedItem = Object.assign({}, item);
      console.log(item.id);
      this.dialogInsured = true;
    },

    close() {
      this.dialog = false;
      this.dialogCancel = false;
      this.dialogDelete = false;
      this.dialogUpload = false;
      this.dialogRenew = false;
      setTimeout(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.currencyMask = "";
        this.editedIndex = -1;
      }, 300);
    },

    save() {
      console.log(this.editedItem);
      console.log(this.$refs.form.validate());
      if (this.$refs.form.validate()) {
        if (this.editedIndex > -1) {
          Object.assign(this.policies[this.editedIndex], this.editedItem);
          this.editedItem.endorsed = this.editedItem.endorsed ? 1 : 0;
          this.editedItem.type = this.editedItem.type.id ? this.editedItem.type.id : this.editedItem.type;
          this.editedItem.ramo = this.editedItem.ramo.id ? this.editedItem.ramo.id : this.editedItem.ramo;
          // this.editedItem.type_currency = this.editedItem.type_currency.id ? this.editedItem.type_currency.id : this.editedItem.type_currency;
          this.updateCustomer(this.editedItem);
        } else {
          this.policies.push(this.editedItem);
          this.editedItem.endorsed = this.editedItem.endorsed ? 1 : 0;
          this.editedItem.type = this.editedItem.type.id ? this.editedItem.type.id : this.editedItem.type;
          this.editedItem.ramo = this.editedItem.ramo.id ? this.editedItem.ramo.id : this.editedItem.ramo;
          // this.editedItem.type_currency = this.editedItem.type_currency.id ? this.editedItem.type_currency.id : this.editedItem.type_currency;
          this.addCustomer(this.editedItem);
        }
      }
    },
  },
  async created() {
    await this.getCustomers();
    this.getBanks();
    this.getInsurers();
    this.getPolicies();
  }
};
</script>

