<template>
<div>

  <v-card elevation="0">
    <v-card-title>
      {{titulo}}
      <v-spacer></v-spacer>
      <!-- <v-text-field
        v-model="search"
        append-icon="fas fa-search"
        label="Buscar"
        single-line
        hide-details
      ></v-text-field> -->
    </v-card-title>

    <v-data-table
      :headers="headers"
      :items="banks"
      :search="search"
      sort-by="nombre"
      class="elevation-1"
      :loading="loading"
      loading-text="Consultando datos... Espere por favor"
    >
      <template v-slot:top>
        <v-toolbar flat color="white">
          <v-spacer></v-spacer>
          <v-dialog v-model="dialog" max-width="500px" v-if="policy">
            <template v-slot:activator="{ on }">
                <v-btn ref="boton2" color="primary" dark class="mb-2" v-on="on">Nuevo Registro</v-btn>
            </template>
            <v-card>
              <v-card-title>
                <span class="headline">{{ formTitle }}</span>
              </v-card-title>

              <v-card-text>
                <v-container>
                  <v-form ref="form" v-model="valid">
                    <v-row>
                      <v-col cols="12" sm="12" md="6">
                        <v-menu
                          ref="menu"
                          v-model="menu"
                          :close-on-content-click="false"
                          transition="scale-transition"
                          offset-y
                          max-width="290px"
                          min-width="auto"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              v-model="editedItem.date"
                              :rules="editedItem.dateRules"
                              label="Fecha"
                              prepend-icon="mdi-calendar"
                              v-bind="attrs"
                              v-on="on"
                              readonly
                            ></v-text-field>
                          </template>
                          <v-date-picker
                            v-model="editedItem.date"
                            no-title
                            @input="menu = false"
                          ></v-date-picker>
                        </v-menu>
                      </v-col>

                      <v-col cols="12" sm="12" md="6">
                        <v-text-field
                          v-model="editedItem.amount_insured"
                          :rules="editedItem.amount_insuredRules"
                          label="Suma Asegurada"
                          type="number"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="12" md="12">
                        <template>
                          <v-file-input
                            v-model="files"
                            color="deep-purple accent-4"
                            counter
                            :label="path_label"
                            placeholder="Selecciona el archivo"
                            prepend-icon="mdi-paperclip"
                            accept="application/pdf"
                            outlined
                            :show-size="1000"
                          >
                            <template v-slot:selection="{ index, text }">
                              <v-chip
                                v-if="index < 2"
                                color="deep-purple accent-4"
                                dark
                                label
                                small
                              >
                                {{ text }}
                              </v-chip>

                              <span
                                v-else-if="index === 2"
                                class="text-overline grey--text text--darken-3 mx-2"
                              >
                                +{{ files.length - 2 }} File(s)
                              </span>
                            </template>
                          </v-file-input>
                        </template>
                      </v-col>
                    </v-row>
                  </v-form>
                </v-container>
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="close">Cancelar</v-btn>
                <v-btn color="blue darken-1" text @click="upload">Guardar</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>

          <v-dialog v-model="dialogDelete" max-width="450">
            <v-card>
              <v-card-title>
                <span>Desea eliminar el incremento a {{editedItem.amount_insured}}?</span>
                <v-spacer></v-spacer>
              </v-card-title>

              <v-card-actions>
                <v-btn color="green darken-1" text @click="close">Cancelar</v-btn>
                <v-btn color="red darken-1" text @click="eliminar">Aceptar</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>

      <template v-slot:[`item.action`]="{ item }">
        <!-- <v-icon small class="mr-2" @click="editItem(item)">fas fa-edit</v-icon> -->
        <v-icon color="red" @click="deleteItem(item)">fas fa-trash-alt</v-icon>
      </template>
      <template v-slot:[`item.path`]="{ item }">
          <v-icon 
          v-if="item.path"
          color="purple darken-3"
          @click="seeDocument(item)"
          >fas fa-file-pdf</v-icon>
        </template>

    </v-data-table>
  </v-card>
</div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import axios from "axios";
import * as moment from 'moment';
export default {
  name: "increments",
  data: function () {
    return {
        dialog: false,
        banks: [],
        files: [],
        dialogDelete: false,
        search: "",
        loading: false,
        titulo: "Incrementos",
        headers: [
        { text: "#Póliza", align: "left", value: "policy_number" },
        { text: "Fecha", align: "left", value: "date" },
        { text: "Suma Asegurada", align: "left", value: "amount_insured" },
        { text: "Documento", sortable: false, value: "path" },
        { text: "Acciones", value: "action", sortable: false }
        ],
        policies: [],
        last_amount_insured: "5000",
        editedIndex: -1,

        valid: true,
        menu: false,
        editedItem: {
        id: "",
        name: "",
        date: moment().format('YYYY-MM-DD'),
        dateRules: [v => !!v || "Escoge la fecha del incremento"],
        amount_insured: "",
        amount_insuredRules: [v => !!v || "Escribe la suma asegurada"],
        active: false
        },
        defaultItem: {
        name: "",
        date: moment().format('YYYY-MM-DD'),
        dateRules: [v => !!v || "Escoge la fecha del incremento"],
        amount_insured: "",
        amount_insuredRules: [v => !!v || "Escribe la suma asegurada"],
        active: false
        }
    }
  },
//   data: () => ({
    
//   }),

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "Nuevo Registro" : "Editar Registro";
    },
    path_label(){
      return this.editedItem.filename;
    },
    ...mapState(["db","headers_db"]),
  },

  watch: {
    dialog(val) {
      val || this.close();
    }
  },

  methods: {
    ...mapActions(["cerrarSesion", "validateSession"]),

    // Metodos base de datos
    async getBanks(){
      this.banks = [];
      if (!this.policy > 0) {
        return;
      }
      
      await axios.get(this.db + 'increment/policy/' + this.policy,
        {
          headers: this.headers_db
        }).then(response => {
          this.banks = response.data.data;
          this.loading = false;
          console.log(response.data.data);
        })
        .catch((error) => {
          this.loading = false;
          this.validateSession(error).then((resp) => { if(resp) this.getBanks(); })
        });
    },

    deleteProcess(id){

      axios.post(this.db + 'increment/delete', { id },
      {
        headers: this.headers_db
      })
      .then((response) => {
        console.log(response);
        this.banks = this.banks.filter(doc => {
        return doc.id != id
        })
      })
      .catch((error) => {
        console.log(error);
        this.validateSession(error).then((resp) => { if(resp) this.deleteProcess(id); })
      });

    },

    async upload() {
      console.log(this.files.size);
      if (this.files.size == undefined) {
        this.$store.dispatch("setSnackbar", {
          text: 'Agregue un documento',
          color: 'warning'
        });
        return;
      }

      if (this.$refs.form.validate()) {
        if (this.files.size > 0) {
            let formData = new FormData();
            
            // files
            formData.append('files', this.files);
            formData.append('name', this.files.name);
            formData.append('id_policy', this.policy);
            formData.append('amount_insured', this.editedItem.amount_insured);
            formData.append('date', this.editedItem.date);

            await axios.post(this.db + "increment/upload", formData,
            {
              headers: {
                  'Content-Type': 'multipart/form-data',
                  'Authorization': 'BEARER ' + localStorage.token
              }
            })
            .then(response => {
                this.$store.dispatch("setSnackbar", {
                  text: 'Documento subido con éxito',
                });
                this.getBanks();
                console.log({ response });
            })
            .catch(error => {
                this.validateSession(error).then((resp) => { if(resp) this.upload(); })
            });
        } else {
            console.log("there are no files.");
        }
        this.dialog = false;
      }
    },

    async seeDocument(item){
      await axios.get(this.db + 'customer/download/' + item.path,
      {
        responseType: 'arraybuffer',
        headers: {
            // 'Content-Type': 'multipart/form-data',
            "Content-type": "application/x-www-form-urlencoded",
            'Authorization': 'BEARER ' + localStorage.token
        }
      })
      .then(response => {
          console.log(response );
          let blob = new Blob([response.data], { type: 'application/pdf' } ),
          url = window.URL.createObjectURL(blob)

          window.open(url);
      })
      .catch(error => {
          console.log( error );
          if (error.response.status == 401) {
            this.getBanks();
          }
      });
    },
    // FIN Metodos base de datos

    // Metodos Generales
    selectOption() {
      if (!this.policy) {
        this.$refs.form2.validate();
      }
    },

    editItem(item) {
      console.log(item);
      this.editedIndex = this.banks.indexOf(item);
      this.dialog = true;
      this.editedItem = Object.assign({}, item);
    },

    deleteItem(item) {
      console.log(item.id);

      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    close() {
      this.dialog = false;
      this.dialogDelete = false;
      setTimeout(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      }, 300);
    },

    save() {
      if (this.$refs.form.validate() && this.$refs.form2.validate()) {
        if (this.editedIndex > -1) {
          Object.assign(this.banks[this.editedIndex], this.editedItem);
          this.updateProcess(this.editedItem);
        } else {
          this.banks.push(this.editedItem);
          this.addProcess(this.editedItem);
        }
        this.close();
      }
    },

    eliminar() {
      this.deleteProcess(this.editedItem.id);
      this.close();
    }
  },
  mounted() {
    this.getBanks();
  },
  props: {
      policy: String
  }
};
</script>

