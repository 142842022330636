<template>
<div>

  <v-card elevation="0">
    <v-card-title>
      {{titulo}}
      <v-spacer></v-spacer>
      <!-- <v-text-field
        v-model="search"
        append-icon="fas fa-search"
        label="Buscar"
        single-line
        hide-details
      ></v-text-field> -->
    </v-card-title>

    <v-data-table
      :headers="headers"
      :items="insureds"
      :search="search"
      sort-by="name"
      class="elevation-1"
      :loading="loading"
      loading-text="Consultando datos... Espere por favor"
    >
      <template v-slot:[`item.amount_insured`]="{ item }">
            <div>
              {{ numberWithCommas((item.amount_insured)) }}
            </div>
      </template>
      <template v-slot:top>
        <v-toolbar flat color="white">
          <v-spacer></v-spacer>

          <v-dialog v-model="insuredDialog" max-width="700px">
            <template v-slot:activator="{ on }">
                <v-btn @click="filterInsureds" ref="boton2" color="indigo" dark v-on="on">Agregar Asegurados</v-btn>
            </template>
            
            
            <v-card>
              <v-card-title>
                <span class="headline">Asegurados del Cliente</span>
              </v-card-title>

              <v-card-text>
                <v-container>

                  <v-data-table
                    v-model="policyholder"
                    :headers="headers_holders"
                    :items="policyholders"
                    sort-by="name"
                    class="elevation-1"
                    :loading="loading"
                    loading-text="Consultando datos... Espere por favor"
                    show-select
                    item-key="id"
                    checkbox-color="success"
                    :hide-default-footer="true"
                  >

                  </v-data-table>

                </v-container>
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="grey darken-1" text @click="close">Cancelar</v-btn>
                <v-btn color="success darken-1" text @click="save">Agregar</v-btn>
              </v-card-actions>
            </v-card>

          </v-dialog>

          <v-dialog v-model="dialogDelete" max-width="450">
            <v-card>
              <v-card-title>
                <span>¿Eliminar a {{editedItem.name}}?</span>
                <v-spacer></v-spacer>
              </v-card-title>

              <v-card-actions>
                <v-btn color="green darken-1" text @click="close">Cancelar</v-btn>
                <v-btn color="red darken-1" text @click="eliminar">Aceptar</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>

      <template v-slot:[`item.action`]="{ item }">
        <v-icon color="red" @click="deleteItem(item)">fas fa-trash-alt</v-icon>
      </template>

    </v-data-table>
  </v-card>
</div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import axios from "axios";
export default {
  name: "properties",
  data: function () {
    return {
        dialog: false,
        insureds: [],
        policyholders: [],
        policyholder: [],
        dialogDelete: false,
        insuredDialog: false,
        search: "",
        loading: false,
        titulo: "Bienes",
        headers: [
          { text: "#Certificado", align: "left", sortable: false, value: "certificate_number" },
          { text: "Identidad", align: "left", sortable: false, value: "identity" },
          { text: "Nombre", value: "name" },
          { text: "Dependientes", value: "dependents" },
          { text: "Fecha de alta", value: "date" },
          { text: "Acciones", align: "center", value: "action", sortable: false }
        ],
        headers_holders: [
          { text: "Identidad", align: "left", sortable: false, value: "identity" },
          { text: "#Certificado", align: "left", sortable: false, value: "certificate_number" },
          { text: "Nombre", value: "name" },
        ],
        editedIndex: -1,

        valid: true,
        editedItem: {
          id: "",
        },
        defaultItem: {
          id: "",
        }
    }
  },
//   data: () => ({
    
//   }),

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "Nuevo Registro" : "Editar Registro";
    },
    path_label(){
      return this.editedItem.filename;
    },
    ...mapState(["db","headers_db"]),
  },

  watch: {
    dialog(val) {
      val || this.close();
    }
  },

  methods: {
    ...mapActions(["cerrarSesion", "validateSession"]),

    // Metodos base de datos
    addInsured(payload){

      axios.post(this.db + 'insured/add/' + this.id_policy, payload,
      {
        headers: this.headers_db
      })
      .then((response) => {
        console.log(response);
        this.$store.dispatch("setSnackbar", {
          text: response.data.message,
        });
        this.getInsureds();
      })
      .catch((error) => {
        this.validateSession(error).then((resp) => { if(resp) this.addInsured(payload); })
      });

    },

    // Policyholders || Insureds
    async getPolicyholders(){
      console.log(this.id_customer);
      this.policyholders = [];
      if (!this.id_customer > 0) {
        return;
      }
      
      await axios.get(this.db + 'policyholders/' + this.id_customer,
        {
          headers: this.headers_db
        }).then(response => {
          this.policyholders = response.data.data;
          this.loading = false;
          console.log(response.data.data);
        })
        .catch((error) => {
          this.validateSession(error).then((resp) => { if(resp) this.getPolicyholders(); })
        });
    },

    async getInsureds(){
      this.insureds = [];
      if (!this.id_policy > 0) {
        return;
      }
      
      await axios.get(this.db + 'insureds/' + this.id_policy,
        {
          headers: this.headers_db
        }).then(response => {
          this.insureds = response.data.data;
          this.loading = false;
          console.log(response.data.data);
        })
        .catch((error) => {
          this.validateSession(error).then((resp) => { if(resp) this.getInsureds(); })
        });
    },

    deleteInsured(id){

      axios.post(this.db + 'insured/delete', { id },
      {
        headers: this.headers_db
      })
      .then((response) => {
        console.log(response);
        this.insureds = this.insureds.filter(doc => {
        return doc.id != id
        })
      })
      .catch((error) => {
        this.validateSession(error).then((resp) => { if(resp) this.deleteInsured(id); })
      });

    },
    // FIN Metodos base de datos


    // Metodos Generales
    filterInsureds(){
      this.insureds.forEach(element => {
        this.policyholders = this.policyholders.filter(doc => {
        return doc.identity != element.identity
        })
      });
    },

    editItem(item) {
      console.log(item);
      this.editedIndex = this.banks.indexOf(item);
      this.dialog = true;
      this.editedItem = Object.assign({}, item);
    },

    deleteItem(item) {
      console.log(item.id);

      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    close() {
      this.dialog = false;
      this.dialogDelete = false;
      this.insuredDialog = false;
      setTimeout(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      }, 300);
    },

    save() {
      if (!this.policyholder.length > 0) {
        console.log("Seleccione Items");
        return;
      }
      // this.editedItem.id_policy = this.id_policy;
      this.addInsured(this.policyholder);
      this.close();
    },

    eliminar() {
      this.deleteInsured(this.editedItem.id);
      this.close();
    }
  },
  mounted() {
    this.getInsureds();
    this.getPolicyholders();
  },
  props: {
      id_policy: String,
      id_customer: String
  }
};
</script>

