var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',{attrs:{"elevation":"0"}},[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.titulo)+" "),_c('v-spacer')],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.banks,"search":_vm.search,"sort-by":"nombre","loading":_vm.loading,"loading-text":"Consultando datos... Espere por favor"},scopedSlots:_vm._u([{key:"item.validity_date1",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(" "+_vm._s(_vm.validityFormat(item.validity_date1, item.validity_date2))+" ")])]}},{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":"","color":"white"}},[_c('v-spacer'),(_vm.policy)?_c('v-dialog',{attrs:{"max-width":"500px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({ref:"boton2",staticClass:"mb-2",attrs:{"color":"primary","dark":""}},on),[_vm._v("Nuevo Registro")])]}}],null,false,2831889369),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v(_vm._s(_vm.formTitle))])]),_c('v-card-text',[_c('v-container',[_c('v-form',{ref:"form",model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12"}},[_c('v-menu',{ref:"menu",attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"rules":_vm.editedItem.dateRules,"label":"Vigencia","prepend-icon":"mdi-calendar","readonly":""},model:{value:(_vm.editedItem.date),callback:function ($$v) {_vm.$set(_vm.editedItem, "date", $$v)},expression:"editedItem.date"}},'v-text-field',attrs,false),on))]}}],null,false,4065167084),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('v-date-picker',{attrs:{"no-title":""},on:{"input":function($event){_vm.menu = false},"change":_vm.saveDate},model:{value:(_vm.editedItem.date),callback:function ($$v) {_vm.$set(_vm.editedItem, "date", $$v)},expression:"editedItem.date"}})],1)],1),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12"}},[[_c('v-file-input',{attrs:{"color":"deep-purple accent-4","counter":"","label":_vm.path_label,"placeholder":"Selecciona el archivo","prepend-icon":"mdi-paperclip","accept":"application/pdf","outlined":"","show-size":1000},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var index = ref.index;
var text = ref.text;
return [(index < 2)?_c('v-chip',{attrs:{"color":"deep-purple accent-4","dark":"","label":"","small":""}},[_vm._v(" "+_vm._s(text)+" ")]):(index === 2)?_c('span',{staticClass:"text-overline grey--text text--darken-3 mx-2"},[_vm._v(" +"+_vm._s(_vm.files.length - 2)+" File(s) ")]):_vm._e()]}}],null,false,4244939259),model:{value:(_vm.files),callback:function ($$v) {_vm.files=$$v},expression:"files"}})]],2)],1)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.close}},[_vm._v("Cancelar")]),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.upload}},[_vm._v("Guardar")])],1)],1)],1):_vm._e(),_c('v-dialog',{attrs:{"max-width":"450"},model:{value:(_vm.dialogDelete),callback:function ($$v) {_vm.dialogDelete=$$v},expression:"dialogDelete"}},[_c('v-card',[_c('v-card-title',[_c('span',[_vm._v("Desea eliminar el incremento a "+_vm._s(_vm.editedItem.amount_insured)+"?")]),_c('v-spacer')],1),_c('v-card-actions',[_c('v-btn',{attrs:{"color":"green darken-1","text":""},on:{"click":_vm.close}},[_vm._v("Cancelar")]),_c('v-btn',{attrs:{"color":"red darken-1","text":""},on:{"click":_vm.eliminar}},[_vm._v("Aceptar")])],1)],1)],1)],1)]},proxy:true},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{attrs:{"color":"red"},on:{"click":function($event){return _vm.deleteItem(item)}}},[_vm._v("fas fa-trash-alt")])]}},{key:"item.path",fn:function(ref){
var item = ref.item;
return [(item.path)?_c('v-icon',{attrs:{"color":"purple darken-3"},on:{"click":function($event){return _vm.seeDocument(item)}}},[_vm._v("fas fa-file-pdf")]):_vm._e()]}}],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }